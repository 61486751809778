import type { PageProps } from 'gatsby'
import * as React from 'react'
import ReturnProLogo from '../assets/icons/ReturnPro-logo.svg'
import ReturnProLogoSmall from '../assets/icons/ReturnPro-small-icon.svg'
import AmazonIcon from '../assets/icons/amazon-icon.svg'
import CheckIcon from '../assets/icons/check-icon.svg'
import ReturnsIcon from '../assets/icons/returns-icon.svg'
import ShopifyIcon from '../assets/icons/shopify-icon.svg'
import WallmartIcon from '../assets/icons/wallmart-icon.svg'
import { CallToAction } from '../components/CallToAction'
import { Container } from '../components/Container'
import { SEO } from '../components/SEO'
import { Typography } from '../components/Typography'
import { EXTERNAL_URLS, INTERNAL_ROUTES } from '../constants'
import { ExternalButtonLink } from '../components/ExternalButtonLink'

const items = [
  { icon: ReturnProLogoSmall },
  { icon: ReturnsIcon, size: 'w-[2.125rem]' },
  { icon: ShopifyIcon, size: 'w-[5.25rem] h-[5.5rem]' },
  { icon: WallmartIcon },
  { icon: AmazonIcon },
]

const benefits = [
  {
    spanText: 'All your returns in one platform:',
    text: 'View and manage all of your returns from any marketplace you sell on, from a single platform.',
  },
  {
    spanText: 'Set up in under 20 minutes:',
    text: 'Quickly set up your ReturnPro account and start managing your returns with ease.',
  },
  {
    spanText: 'The ultimate shopper experience:',
    text: 'User-friendly features to ensure the shopper receives a tailored experience that aligns perfectly with the seller’s vision.',
  },
  {
    spanText: 'Keep it, exchanges, & refunds',
    text: 'Enable keep-it services for returns that are not economical; encourage exchanges to reduce dollars lost to returns and allow instant refunds.',
  },
  {
    spanText: '10,000+ drop-off locations for unparalleled convenience:',
    text: 'Activate thousands of drop-off locations across the US for unparalleled convenience.',
  },
  {
    spanText: 'Dedicated returns centers for processing:',
    text: '11 returns centers performing value add services across the US and Canada, such as receiving, sorting, refurbishing, sanitizing, repackaging, fulfillment, recycling, donating, and return to vendor.',
  },
  {
    spanText: 'ReCommerce services resale engine:',
    text: 'Maximize the value of your returns by selling them back on your original store and simultaneously across multiple integrated marketplaces, including Walmart.com, Amazon, eBay, and more.',
  },
]

const HomePage: React.FC<PageProps> = () => {
  return (
    <>
      {/* Hero section */}
      <section className="flex gap-10 max-md:pt-[7.5rem]">
        <Container>
          <div className="flex gap-10">
            <div className="w-1/2 max-md:w-full">
              <ul className="mb-11 flex items-center gap-8 max-md:justify-evenly">
                {items.map((item, index) => {
                  const Icon = item.icon
                  const size = item.size ?? 'w-[5.5rem] h-[5.5rem]'
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index} className={size}>
                      <Icon width="100%" height="100%" />
                    </li>
                  )
                })}
              </ul>
              <Typography.H2 className="mb-3">
                Solving marketplace seller returns with enterprise-grade software, supply chain, and
                ReCommerce services in a single simplified platform
              </Typography.H2>
              <Typography.H1 className="mb-10">
                ReturnPro (formerly goTRG), the leader in returns management.
              </Typography.H1>
              <div className="flex gap-5 max-md:flex-col max-md:text-center">
                <ExternalButtonLink href={EXTERNAL_URLS.SIGN_UP} variant="primary">
                  Get started
                </ExternalButtonLink>
                <ExternalButtonLink href={EXTERNAL_URLS.PRICING} variant="outline">
                  View pricing
                </ExternalButtonLink>
              </div>
            </div>
            <div className="relative w-1/2 max-md:hidden">
              <div className="absolute -right-[10.625rem] -top-[36.25rem] -z-10 h-[54rem] w-[54rem] rounded-full bg-primary-light-grey" />
              <div className="absolute h-[39.3125rem] w-[59rem] rounded-xl bg-hero-image bg-cover shadow-base" />
              <p className="absolute -bottom-8 flex items-baseline gap-2 font-roboto text-[0.9375rem] leading-[1.5] text-[#666]">
                <span>Seller returns powered by</span>
                <ReturnProLogo width="5.875rem" height="1rem" />
              </p>
            </div>
          </div>
        </Container>
      </section>

      {/* Benefits section  */}
      <section className="bg-grain-texture-pattern">
        <Container>
          <div className="flex gap-10 max-md:flex-col">
            <div className="flex w-1/2 flex-col gap-10 max-md:w-full">
              <div className="h-[18rem] max-w-[31rem] rounded-xl border-border-color bg-connect-shop bg-[length:110%] bg-[50%_30%] shadow-base max-md:h-56 max-md:w-full max-md:max-w-none max-md:bg-no-repeat" />
              <div className="h-[18rem] max-w-[31rem] rounded-xl border-border-color bg-experience-portal bg-cover shadow-base max-md:h-56 max-md:w-full max-md:max-w-none max-md:bg-no-repeat" />
              <div className="h-[18rem] max-w-[31rem] rounded-xl border-border-color bg-intelligence-disposition bg-cover shadow-base max-md:h-56 max-md:w-full max-md:max-w-none max-md:bg-no-repeat" />
            </div>
            <div className="w-1/2 max-md:w-full">
              <Typography.H3 className="mb-5">
                The ultimate platform for managing all your returns
              </Typography.H3>
              <p className="body-text mb-5 font-uniform-rnd text-nav-item-text">
                We reimagined our enterprise platform used by the biggest retailers & brands and
                tailored it for Walmart, Shopify, Amazon, and other 3P marketplace sellers.
                <br />
                <br />
                Our team of experts restore +25M items back to life annually at our network of
                dedicated returns centers and then relist them to your store or to any of our 20+
                integrated marketplaces such as Walmart, Shopify, and Amazon.
              </p>
              <ul className="mb-5 flex flex-col gap-4 font-uniform-rnd">
                {benefits.map(benefit => (
                  <li key={benefit.spanText} className="flex gap-4">
                    <div>
                      <CheckIcon className="w-6" />
                    </div>
                    <p className="text-xl text-nav-item-text">
                      <span className="font-bold">{benefit.spanText}</span>{' '}
                      <span className="font-normal">{benefit.text}</span>
                    </p>
                  </li>
                ))}
              </ul>
              <ExternalButtonLink
                href={EXTERNAL_URLS.SIGN_UP}
                variant="primary"
                className="max-sm:w-full"
              >
                Get started
              </ExternalButtonLink>
            </div>
          </div>
        </Container>
      </section>

      {/* CTA section */}
      <CallToAction>
        <ExternalButtonLink
          href={EXTERNAL_URLS.SIGN_UP}
          variant="reversed"
          className="max-sm:w-full"
        >
          Get started
        </ExternalButtonLink>
      </CallToAction>
    </>
  )
}

export default HomePage

export const Head = () => <SEO title="Home page" />
